import Box from "@mui/material/Box";
import { useEffect } from "react";

export const MenuImagesComponent = () => {
  const imageList = [
    'img/menu/intro.png',
    'img/menu/baguette.png',
    'img/menu/frutal-mix.png',
    'img/menu/batidos.png',
    'img/menu/detox.png',
    'img/menu/combinados.png',
    'img/menu/frutas.png',
    'img/menu/otros.png',
    'img/menu/jugos-y-licuados.png',
    'img/menu/sandwich-y-bebidas-calientes.png',
    'img/menu/fin.png',
  ]

  useEffect(() => {
    document.title = "Zumo's - Menú"
  }, []);

  return (
    <>
      {imageList.map(img => (
        <Box
          component="img"
          src={img}
          sx={{
            maxWidth: {
              xs: "100%",
            },
            objectFit: "cover",
          }}
        />
      ))}
    </>
  )
}